import * as React from "react"
import SearchSelect from "./searchSelect"

const SearchRow = () => {
    return <div className="row ticker-search-row mt-4">
        <div className="col">
            <form>
                <div className="form-row">
                    <div className="col-12 col-sm-8 offset-sm-2 text-left">
                        {/* <input className="form-control ticker-search" type="text" placeholder="Search ticker..." name="t" autoFocus={true}/>
                        <SimpleSelect placeholder="Select a fruit" theme='bootstrap4' onValueChange={value => alert(value)}>
                            <option value = "apple">apple</option>
                            <option value = "mango">mango</option>
                            <option value = "orange">orange</option>
                            <option value = "banana">banana</option>
                        </SimpleSelect> */}
                        <SearchSelect placeholder="Ticker..."/> 
                    </div>
                </div>
            </form>
        </div>
    </div>

}

export default SearchRow

import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SearchRow from "../components/tickers/SearchRow"

const IndexPage = () => (
  <Layout searchInHeader={false}>
    <Seo title="Home" />
    <section className="py-5 container">
      <div className="row py-lg-5 text-center">
        <div className="col-lg-6 col-md-8 mx-auto">
          {/* <h1 className="fw-light">This is a tool that draws trend lines on a stock chart&nbsp;automatically</h1> */}
          {/* <p className="lead text-muted">Try it in action</p> */}
          <h1 className="fw-light">Select ticker to show chart with&nbsp;a&nbsp;trend&nbsp;lines</h1>
        </div>
      </div>
      <SearchRow/>
      <div className="row mt-4" style={{marginTop: "10px"}}>
        <div className="col-12 col-sm-8 offset-sm-2 text-center text-muted"><span>Examples</span>
          <br></br>
        </div>
      </div>
      <div className="row" style={{marginTop: "10px"}}>
        <div className="col-12 col-sm-8 offset-sm-2 text-center text-muted">
          <a className="btn btn-light btn-sm rounded-pill" href="/quote/TSLA">TSLA</a>&nbsp;
          <a className="btn btn-light btn-sm rounded-pill" href="/quote/MSFT">MSFT</a>&nbsp;
          <a className="btn btn-light btn-sm rounded-pill" href="/quote/AAPL">AAPL</a> 
        </div>
      </div>
      <div className="row" style={{marginTop: "10px"}}>

      </div>
      <div className="row" style={{marginTop: "30px"}}>
            <div className="col-12 mx-auto">
            <blockquote className="text-center">
                The trend is your friend until the end when it bends. (c)
            </blockquote>
            </div>
        </div>
    </section>
  </Layout>
)

export default IndexPage

import * as React from "react"
import Select from "../select/Select"
import Creatable from 'react-select/async-creatable'

const SearchSelect = (props) => {

    const promiseOptions = (inputValue: string) =>
        
        new Promise((resolve, reject) => {
            fetch(
                `/api/tickers/search?q=${encodeURIComponent(inputValue)}`
            ).then(
                res => res.json()
            ).then((res) => {
                var results = res['results'];
                var options = results.map((item)  => {
                    return {
                        value: item.id,
                        label: item.text
                    }
                });
                resolve(options);
            }).catch(error => {
                reject();
            });
        });

    const onChange = (e) => {
        console.log('Ticker selected: ' + e.value)
        window.location = '/quote/' + e.value.toUpperCase()
    }
    
    return <Select
            selectType={Creatable}
            loadOptions={promiseOptions}
            onChange={onChange}
            defaultOptions
            // placeholder="Select ticker..."
            aria-label="Ticker"
            formatCreateLabel={userInput => `${userInput.toUpperCase()}`}
            {...props}
        />
}

export default SearchSelect
